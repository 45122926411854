body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
}


.logo{
  width: 90px;
  height: 125px;
  position: absolute;
  top: 3vw;
  left: 3vw;
  background-image: url(cardan-logo-01.svg);
  background-repeat: no-repeat;
  background-size: contain;
}